// WeeklyChart.js
import React, { useState, useEffect } from "react"
import { useTheme } from "@material-ui/core/styles"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import { subDays, startOfDay, parseISO, format } from "date-fns"
import { es } from "date-fns/locale"

const WeeklyChart = ({ tickets }) => {
  const theme = useTheme()
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    // Process daily data for the past week
    const dailyData = Array.from({ length: 7 }, (_, i) => {
      const date = subDays(new Date(), 6 - i)
      return {
        date: format(date, "EEE", { locale: es }),
        nuevos: 0,
        resueltos: 0,
      }
    })

    tickets.forEach(ticket => {
      const ticketDate = startOfDay(parseISO(ticket.createdAt))
      const resolvedDate = ticket.status === "closed" ? startOfDay(parseISO(ticket.updatedAt)) : null
      
      const createdIndex = dailyData.findIndex(
        d => d.date === format(ticketDate, "EEE", { locale: es })
      )
      if (createdIndex !== -1) {
        dailyData[createdIndex].nuevos++
      }
      
      if (resolvedDate) {
        const resolvedIndex = dailyData.findIndex(
          d => d.date === format(resolvedDate, "EEE", { locale: es })
        )
        if (resolvedIndex !== -1) {
          dailyData[resolvedIndex].resueltos++
        }
      }
    })

    setChartData(dailyData)
  }, [tickets])

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="nuevos"
          name="Tickets Nuevos"
          stroke={theme.palette.primary.main}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="resueltos"
          name="Tickets Resueltos"
          stroke={theme.palette.secondary.main}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default WeeklyChart