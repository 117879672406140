import React, { useState, useEffect } from "react";
import { Avatar, CardHeader, IconButton } from "@material-ui/core";
import { Person, PersonAddOutlined, LocalShippingOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import api from "../../services/api";
import ClientEditDialog from "../../pages/Clients/ClientEditDialog";
import {
    Comment as CommentIcon,
    Close as CloseIcon
} from "@material-ui/icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        gap: '8px',
        marginRight: 16,
        flexShrink: 0,
    },
    clientButton: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    commentButton: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        backgroundColor: '#FFA500',
        color: '#fff',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#FF8C00',
            transform: 'scale(1.1)',
        }
    },
    existingClient: {
        backgroundColor: '#50C878',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#45B069',
        }
    },
    newClient: {
        backgroundColor: '#FF6B6B',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#FF5252',
        }
    },
    packagesButton: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        backgroundColor: '#4A90E2',
        color: '#fff',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#357ABD',
            transform: 'scale(1.1)',
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: 0, // Enable text truncation
    },
    cardHeaderRoot: {
        flex: 1,
        minWidth: 0, // Enable text truncation
        padding: '16px 8px', // Reduce padding to give more space
    },
    titleText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    commentDialog: {
        padding: theme.spacing(2),
    },
    commentDialogTitle: {
        backgroundColor: '#FFA500',
        color: '#fff',
    },
    commentContent: {
        padding: theme.spacing(2),
        minWidth: 300,
        maxWidth: 500,
    }
}));

const TicketInfo = ({ contact, ticket, onClick }) => {
    const classes = useStyles();
    const [clientData, setClientData] = useState(null);
    const [showClientDialog, setShowClientDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [clientFirstName, setClientFirstName] = useState("");
    const [clientLastName, setClientLastName] = useState("");
    useEffect(() => {
        const fetchClient = async () => {
            if (!contact?.number) return;

            try {
                const { data } = await api.get('api/clients', {
                    params: {
                        search: contact.number
                    }
                });

                if (data.data && data.data.length > 0) {
                    setClientData(data.data[0]);
                } else {
                    setClientData(null);
                    let clientNames = (contact.name).split(" ");
                    setClientFirstName(clientNames[0] || "");
                    setClientLastName(clientNames[1] || "")
                }
            } catch (error) {
                console.error('Error fetching client:', error);
                toast.error('Error al buscar cliente');
            } finally {
                setLoading(false);
            }
        };

        fetchClient();
    }, [contact?.number]);

    const handleClientButton = () => {
        setShowClientDialog(true);
    };

    const handleSaveClient = async (data) => {
        try {
            if (data.id) {
                await api.put(`api/clients/${data.id}`, data);
                toast.success("Cliente actualizado correctamente");
            } else {
                await api.post('api/clients', data);
                toast.success("Cliente creado correctamente");
            }
            const response = await api.get('api/clients', {
                params: { search: contact.number }
            });
            if (response.data.data.length > 0) {
                setClientData(response.data.data[0]);
            }
            setShowClientDialog(false);
        } catch (error) {
            if (error.response?.status === 409) {
                toast.error('Ya existe un cliente con este número de teléfono');
            } else {
                toast.error('Error al guardar cliente');
            }
            throw error;
        }
    };

    return (
        <>
            <div className={classes.headerContainer}>
                <CardHeader
                    onClick={onClick}
                    style={{ cursor: "pointer" }}
                    classes={{
                        root: classes.cardHeaderRoot,
                    }}
                    titleTypographyProps={{
                        className: classes.titleText,
                        variant: "subtitle1"
                    }}
                    subheaderTypographyProps={{
                        className: classes.titleText,
                        variant: "body2"
                    }}
                    avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
                    title={`${contact.name} #${ticket.id}`}
                    subheader={
                        ticket.user &&
                        `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
                    }
                />
                {!loading && (
                    <div className={classes.buttonContainer}>
                        <IconButton
                            onClick={handleClientButton}
                            className={`${classes.clientButton} ${clientData ? classes.existingClient : classes.newClient}`}
                            title={clientData ? "Editar cliente" : "Crear cliente"}
                        >
                            {clientData ? <Person /> : <PersonAddOutlined />}
                        </IconButton>
                    </div>
                )}
            </div>

            {showClientDialog && (
                <ClientEditDialog
                    open={showClientDialog}
                    onClose={() => setShowClientDialog(false)}
                    clientData={clientData || { phone: contact.number, first_name: clientFirstName, last_names: clientLastName }}
                    onSave={handleSaveClient}
                    isNew={!clientData}
                />
            )}
        </>
    );
};

export default TicketInfo;