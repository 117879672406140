// Clients.js - Main component
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	TextField,
	Grid,
	TablePagination,
	InputAdornment,
	CircularProgress,
	Fab,
	IconButton,
	Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { Add, AddBox, Contacts, House, LocalShippingOutlined, Notes, Person, PersonAddOutlined, PersonOutline, Smartphone } from '@material-ui/icons';
import axios from 'axios';
import ClientEditDialog from './ClientEditDialog';
import { toast } from 'react-toastify';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '93%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		padding: theme.spacing(2),
		fontFamily: '"Segoe UI", "Inter", "Roboto", sans-serif',
		backgroundColor: theme.palette.type === 'dark' ? '#1a1b1e' : 'inherit',
	},
	searchContainer: {
		marginBottom: theme.spacing(3)
	},
	searchField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: '12px',
			fontSize: '1.1rem',
			backgroundColor: theme.palette.type === 'dark' ? '#2d2d30' : 'white',
			boxShadow: theme.palette.type === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.05)',
			transition: 'all 0.2s ease',
			'&:hover': {
				boxShadow: theme.palette.type === 'dark' ? '0 4px 8px rgba(0,0,0,0.3)' : '0 4px 8px rgba(0,0,0,0.1)'
			}
		}
	},
	headerRow: {
		display: 'grid',
		gridTemplateColumns: '3fr 1.5fr 1fr 1fr 1fr',
		gap: '12px',
		padding: '10px 20px',
		width: '100%',
		fontWeight: 600,
		color: theme.palette.type === 'dark' ? '#e2e8f0' : '#1e293b',
		fontSize: '0.9rem',
		backgroundColor: theme.palette.type === 'dark' ? '#2d2d30' : '#f8fafc',
		borderRadius: '12px',
		marginBottom: theme.spacing(1),
		boxShadow: theme.palette.type === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.02)',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	tableContainer: {
		flex: 1,
		overflowY: 'auto',
		minHeight: 0,
		position: 'relative',
	},
	row: {
		display: 'grid',
		gridTemplateColumns: '3fr 1.5fr 1fr 1fr 1fr',
		gap: '12px',
		padding: '11px 20px',
		width: '100%',
		alignItems: 'center',
		cursor: 'pointer',
		backgroundColor: theme.palette.type === 'dark' ? '#2d2d30' : '#efefef6e',
		marginBottom: '10px',
		borderRadius: '12px',
		boxShadow: theme.palette.type === 'dark' ? '0 4px 6px rgba(0,0,0,0.2)' : '0 4px 6px rgba(0,0,0,0.05)',
		transition: 'all 0.2s ease',
		'&:hover': {
			boxShadow: theme.palette.type === 'dark' ? '0 8px 12px rgba(0,0,0,0.3)' : '0 8px 12px rgba(0,0,0,0.1)',
			transform: 'translateY(-2px)',
			backgroundColor: theme.palette.type === 'dark' ? '#383838' : '#bbdefb'
		},
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '1fr',
			gap: '12px',
			padding: '20px'
		}
	},
	cell: {
		color: theme.palette.type === 'dark' ? '#e2e8f0' : '#334155',
		fontWeight: 500,
		fontSize: '0.9rem',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		'& .MuiSvgIcon-root': {
			color: theme.palette.type === 'dark' ? '#94a3b8' : 'inherit',
		},
		'&.empty': {
			color: theme.palette.type === 'dark' ? '#4b5563' : '#9ca3af',
		},
		[theme.breakpoints.down('sm')]: {
	'&:before': {
		content: 'attr(data-label)',
		fontWeight: 600,
		marginRight: theme.spacing(1),
		color: theme.palette.type === 'dark' ? '#94a3b8' : '#1e293b'
	},
	whiteSpace: 'normal',
	borderBottom: theme.palette.type === 'dark' ? '1px solid #404045' : '1px solid #f1f5f9',
	paddingBottom: theme.spacing(1)
}
	},
expandedDetails: {
	backgroundColor: theme.palette.type === 'dark' ? '#242427' : '#fff1f2',
		margin: '-16px 0 16px 0',
			borderRadius: '0 0 12px 12px',
				padding: theme.spacing(4),
					boxShadow: theme.palette.type === 'dark' ? 'inset 0 2px 4px rgba(0,0,0,0.2)' : 'inset 0 2px 4px rgba(0,0,0,0.05)',
	},
detailsGrid: {
	display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
			gap: theme.spacing(4),
				[theme.breakpoints.down('sm')]: {
		gridTemplateColumns: '1fr',
		}
},
detailItem: {
	backgroundColor: theme.palette.type === 'dark' ? '#2d2d30' : 'white',
		padding: theme.spacing(3),
			borderRadius: theme.spacing(2),
				boxShadow: theme.palette.type === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.05)',
					'& h3': {
		color: theme.palette.type === 'dark' ? '#e2e8f0' : 'inherit',
			'& .MuiSvgIcon-root': {
			color: theme.palette.type === 'dark' ? '#93c5fd' : 'inherit',
			}
	},
	'& p': {
		color: theme.palette.type === 'dark' ? '#94a3b8' : 'inherit',
		}
},
paginationContainer: {
	marginTop: theme.spacing(1),
		padding: theme.spacing(0),
			backgroundColor: theme.palette.type === 'dark' ? '#2d2d30' : 'white',
				borderRadius: '12px',
					boxShadow: theme.palette.type === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.05)',
						'& .MuiTablePagination-root': {
		fontFamily: '"Segoe UI", "Inter", "Roboto", sans-serif',
			fontWeight: 500,
				color: theme.palette.type === 'dark' ? '#e2e8f0' : 'inherit'
	}
},
fab: {
	position: 'absolute',
		bottom: theme.spacing(1),
			left: theme.spacing(3),
				backgroundColor: theme.palette.type === 'dark' ? '#1a4f8c' : '#3648df',
					color: 'white',
						width: 80,
							height: 80,
								'& .MuiSvgIcon-root': {
		fontSize: '2.5rem',
		},
	'&:hover': {
		backgroundColor: theme.palette.type === 'dark' ? '#1a5f9c' : '#1e2db0',
		},
	zIndex: 1,
	},
}));

const Clients = () => {
	const classes = useStyles();
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [editClient, setEditClient] = useState(null);
	const [expandedRow, setExpandedRow] = useState(null);
	const [selectedClientPackages, setSelectedClientPackages] = useState(null);

	const handleViewPackages = (client) => {
		setSelectedClientPackages(client);
	};

	const fetchClients = async () => {
		setLoading(true);
		try {
			const { data } = await api.get('api/clients', {
				params: {
					page: page + 1,
					limit: rowsPerPage,
					search: searchTerm || "",
				}
			});

			setClients(data.data);
			setTotalCount(data.pagination.total);
		} catch (error) {
			console.error('Error:', error);
			toast.error('Error al cargar clientes');
		}
		setLoading(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			fetchClients();
		}, 300);
		return () => clearTimeout(timer);
	}, [searchTerm, page, rowsPerPage]);

	const handleSaveClient = async (clientData) => {
		try {
			if (clientData.id) {
				await api.put(`api/clients/${clientData.id}`, clientData);
				toast.success("Cliente actualizado correctamente");
			} else {
				await api.post('api/clients', clientData);
				toast.success("Cliente creado correctamente");
			}
			fetchClients();
			setEditClient(null);
		} catch (error) {
			if (error.response?.status === 409) {
				toast.error('Ya existe un cliente con este número de teléfono');
			} else {
				toast.error('Error al guardar cliente');
			}
			throw error;
		}
	};

	const handleRowClick = (id) => {
		setExpandedRow(expandedRow === id ? null : id);
	};

	return (
		<div className={classes.root}>
			<TextField
				fullWidth
				variant="outlined"
				placeholder="Buscar clientes..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				className={`${classes.searchContainer} ${classes.searchField}`}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>

			{loading ? (
				<div style={{ textAlign: 'center', padding: '20px' }}>
					<CircularProgress />
				</div>
			) : (
				<>
					<div className={classes.tableContainer}>
						<div className={classes.headerRow}>
							<div>Nombre</div>
							<div>Teléfono</div>
							<div>Ciudad</div>
							<div>Departamento</div>
							<div>Acciones</div>
						</div>

						{clients.map((client) => (
							<React.Fragment key={client.id}>
								<div
									className={classes.row}
									onClick={() => handleRowClick(client.id)}
								>
									<div className={classes.cell}>
										<PersonOutline style={{
											position: 'relative',
											top: '5px',
										}} /> {`${client.first_name} ${client.middle_name || ''} ${client.last_names}`}
									</div>
									<div className={classes.cell}><Smartphone style={{
										position: 'relative',
										top: '5px',
									}} /> {client.phone}</div>
									<div className={classes.cell}>{client.city || '-'}</div>
									<div className={classes.cell}>{client.department || '-'}</div>
									<div className={classes.cell}>
										<div style={{ display: 'flex', gap: '8px' }}>
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													setEditClient(client);
												}}
											>
												<EditIcon />
											</IconButton>
										</div>
									</div>

								</div>

								{expandedRow === client.id && (
									<div className={classes.expandedDetails}>
										<div className={classes.detailsGrid}>
											<div className={classes.detailItem}>
												<h3><House style={{
													position: 'relative',
													top: '5px',
												}} /> Dirección</h3>
												<p>{client.address || 'No especificada'}</p>
											</div>
											<div className={classes.detailItem}>
												<h3><Contacts style={{
													position: 'relative',
													top: '5px',
												}} /> Contacto</h3>
												<p>Email: {client.email || 'No especificado'}</p>
												<p>Teléfono: {client.phone}</p>
											</div>
											{client.notes && (
												<div className={classes.detailItem}>
													<h3><Notes style={{
														position: 'relative',
														top: '5px',
													}} /> Notas</h3>
													<p>{client.notes}</p>
												</div>
											)}
										</div>
									</div>
								)}
							</React.Fragment>
						))}
					</div>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						className={classes.paginationContainer}
						count={totalCount}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={(e, newPage) => setPage(newPage)}
						onRowsPerPageChange={(e) => {
							setRowsPerPage(parseInt(e.target.value, 10));
							setPage(0);
						}}
					/>
				</>
			)}

			<Fab
				className={classes.fab}
				onClick={() => setEditClient({})}
				aria-label="add"
			>
				<PersonAddOutlined />
			</Fab>

			{editClient !== null && (
				<ClientEditDialog
					open={true}
					onClose={() => setEditClient(null)}
					clientData={editClient}
					onSave={handleSaveClient}
					isNew={!editClient.id}
				/>
			)}
		</div>
	);
};

export default Clients;