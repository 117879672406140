// Dashboard.js
import React, { useContext, useState } from "react"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Divider from "@material-ui/core/Divider"
import {
  Message as MessageIcon,
  Schedule as ScheduleIcon,
  CheckCircle as CheckCircleIcon,
  Notifications as NotificationsIcon,
} from "@material-ui/icons"

import useTickets from "../../hooks/useTickets"
import { AuthContext } from "../../context/Auth/AuthContext"
import { i18n } from "../../translate/i18n"
import WeeklyChart from "./WeeklyChart"
import HourlyChart from "./HourlyChart"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: 120,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[4],
      transform: "translateY(-4px)",
    },
  },
  iconContainer: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 32,
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  value: {
    color: theme.palette.text.primary,
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: theme.spacing(1),
  },
  chartPaper: {
    padding: theme.spacing(2),
    height: 400,
  },
  tabs: {
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabLabel: {
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "none",
  },
  listItem: {
    padding: theme.spacing(1, 2),
  },
  listIcon: {
    color: theme.palette.primary.main,
  },
}))

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box p={0}>{children}</Box>}
  </div>
)

const StatCard = ({ icon: Icon, title, value }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper} elevation={1}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon} />
      </div>
      <Typography className={classes.title} component="h3">
        {title}
      </Typography>
      <Typography className={classes.value} component="h2">
        {value}
      </Typography>
    </Paper>
  )
}

const Dashboard = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const { user } = useContext(AuthContext)
  const userQueueIds = user.queues?.map(q => q.id) || []

  const { tickets, count } = useTickets({
    date: new Date().toISOString(),
    queueIds: JSON.stringify(userQueueIds),
    showAll: "true"
  });

  const openTickets = tickets.filter(t => t.status === "open").length;
  const pendingTickets = tickets.filter(t => t.status === "pending").length;
  const closedTickets = tickets.filter(t => t.status === "closed").length;
  const unreadTickets = tickets.filter(t => t.unreadMessages > 0).length;

  const stats = [
    {
      title: "En Servicio",
      value: openTickets,
      icon: MessageIcon,
    },
    {
      title: "Esperando",
      value: pendingTickets,
      icon: ScheduleIcon,
    },
    {
      title: "Finalizados",
      value: closedTickets,
      icon: CheckCircleIcon,
    },
    {
      title: "No Leídos",
      value: unreadTickets,
      icon: NotificationsIcon,
    },
  ]

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Tabs
        value={tabValue}
        onChange={(_, newValue) => setTabValue(newValue)}
        className={classes.tabs}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Resumen" className={classes.tabLabel} />
        <Tab label="Análisis" className={classes.tabLabel} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={4}>
          {stats.map((stat, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <StatCard {...stat} />
            </Grid>
          ))}
          
          <Grid item xs={12}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" color="primary" gutterBottom>
                Tickets por Semana
              </Typography>
              <WeeklyChart tickets={tickets} />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" color="primary" gutterBottom>
                Últimos Tickets
              </Typography>
              <List>
                {tickets.slice(0, 5).map((ticket, index) => (
                  <React.Fragment key={ticket.id}>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon>
                        <MessageIcon className={classes.listIcon} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={`Ticket #${ticket.id} - ${ticket.contact?.name || 'Sin nombre'}`}
                        secondary={`${ticket.lastMessage || 'Sin mensajes'} - ${ticket.status}`}
                      />
                    </ListItem>
                    {index < 4 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" color="primary" gutterBottom>
                Distribución por Hora
              </Typography>
              <HourlyChart tickets={tickets} />
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
    </Container>
  )
}

export default Dashboard