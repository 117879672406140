import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Badge,
  Collapse,
  Box,
  Tooltip,
} from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import { AssignmentIndOutlined, Security } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    height: '100%',
    background: theme.palette.type === 'dark' ? theme.palette.background.default : '#f8fafc',
    boxShadow: 'inset -1px 0 0 rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: 1,
    transition: theme.transitions.create('background', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuItem: {
    position: 'relative',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -2,
      top: '50%',
      transform: 'translateY(-50%)',
      height: '60%',
      width: 4,
      borderRadius: '0 4px 4px 0',
      background: '#3b82f6',
      opacity: 0,
      transition: 'opacity 0.2s ease',
    },
    '&:hover': {
      background: 'rgba(59, 130, 246, 0.08)',
      '& .MuiListItemIcon-root': {
        color: '#3b82f6',
        transform: 'scale(1.1)',
      },
    },
  },
  menuItemActive: {
    background: 'rgba(59, 130, 246, 0.12) !important',
    '&:before': {
      opacity: 1,
    },
    '& .MuiListItemIcon-root': {
      color: '#3b82f6 !important',
    },
    '& .MuiListItemText-primary': {
      color: '#3b82f6',
      fontWeight: 600,
    },
  },
  toolbarHeight: {
    height: 64,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : '#f8fafc',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1),
    position: 'relative',
  },
  drawerHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiIconButton-root': {
      width: 40,
      height: 40,
      borderRadius: 8,
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' 
          ? 'rgba(255, 255, 255, 0.08)'
          : 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  adminIcon: {
    marginRight: theme.spacing(1),
    transition: 'transform 0.2s ease',
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      color: theme.palette.primary.main,
    },
  },
  menuText: {
    fontWeight: 500,
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subHeader: {
    background: theme.palette.action.hover,
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 1),
    fontSize: '0.75rem',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.default,
      opacity: 0.95,
    },
  },
  divider: {
    margin: theme.spacing(1, 2),
  },
  badge: {
    '& .MuiBadge-badge': {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  nested: {
    paddingLeft: theme.spacing(2),
    '& .MuiListItemIcon-root': {
      minWidth: 36,
    },
  },
  arrowIcon: {
    transition: 'transform 0.3s ease',
    transform: props => props.openAdmin ? 'rotate(-180deg)' : 'rotate(0)',
  },
}));

function ListItemLink({ icon, primary, to, className }) {
  const location = useLocation();
  const classes = useStyles();
  const isActive = location.pathname === to;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <Tooltip title={primary} placement="right" arrow>
        <ListItem
          button
          component={renderLink}
          className={`${classes.menuItem} ${isActive ? classes.menuItemActive : ''} ${className || ''}`}
        >
          {icon && <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>}
          <ListItemText
            primary={primary}
            primaryTypographyProps={{ className: classes.menuText }}
          />
        </ListItem>
      </Tooltip>
    </li>
  );
}

const MainListItems = ({ drawerClose, isDrawerOpen }) => {
  const [openAdmin, setOpenAdmin] = useState(true);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const classes = useStyles({ openAdmin });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter(whats => 
          ["qrcode", "PAIRING", "DISCONNECTED", "TIMEOUT", "OPENING"].includes(whats.status)
        );
        setConnectionWarning(offlineWhats.length > 0);
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const handleAdminClick = () => {
    setOpenAdmin(!openAdmin);
  };

  return (
    <Box className={classes.menuWrapper} onClick={drawerClose}>
      <ListItemLink
        to="/"
        primary="Dashboard"
        icon={<DashboardOutlinedIcon />}
      />
      <ListItemLink
        to="/connections"
        primary={i18n.t("mainDrawer.listItems.connections")}
        icon={
          <Badge 
            badgeContent={connectionWarning ? "!" : 0}
            color="error"
            className={classes.badge}
          >
            <SyncAltIcon />
          </Badge>
        }
      />
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
      />
      <ListItemLink
        to="/clients"
        primary="Clientes"
        icon={<AssignmentIndOutlined />}
      />
      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlinedIcon />}
      />

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider className={classes.divider} />
            <ListItem 
              button 
              onClick={handleAdminClick}
              className={classes.subHeader}
            >
              <ListItemIcon style={{ minWidth: 40 }}>
                <Security style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
              </ListItemIcon>
              {isDrawerOpen && (
                <>
                  <ListItemText 
                    primary={i18n.t("mainDrawer.listItems.administration")}
                    style={{ margin: 0 }}
                  />
                  <KeyboardArrowDownIcon style={{ 
                    color: 'rgba(0, 0, 0, 0.87)', 
                    marginLeft: 'auto' 
                  }} />
                </>
              )}
            </ListItem>

            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
              <ListItemLink
                to="/users"
                primary={i18n.t("mainDrawer.listItems.users")}
                icon={<PeopleAltOutlinedIcon />}
                className={classes.nested}
              />
              <ListItemLink
                to="/queues"
                primary={i18n.t("mainDrawer.listItems.queues")}
                icon={<AccountTreeOutlinedIcon />}
                className={classes.nested}
              />
              <ListItemLink
                to="/settings"
                primary={i18n.t("mainDrawer.listItems.settings")}
                icon={<SettingsOutlinedIcon />}
                className={classes.nested}
              />
              <ListItemLink
                to="/statistics"
                primary="Estadísticas"
                icon={<EqualizerIcon />}
                className={classes.nested}
              />
            </Collapse>
          </>
        )}
      />
    </Box>
  );
};

export default MainListItems;