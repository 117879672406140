import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0f172a', // Deep dark blue-gray background
    position: 'relative',
    overflow: 'hidden',
  },
  backgroundPattern: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1,
    opacity: 0.2,
    pointerEvents: 'none',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: 'rgba(15, 23, 42, 0.8)', // Dark, slightly transparent background
    maxWidth: 400,
    width: '100%',
    position: 'relative',
    zIndex: 10,
    color: '#ffffff', // White text
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(2),
  },
  logo: {
    marginBottom: theme.spacing(3),
    maxWidth: 150, // Reduced logo size
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 600,
    backgroundColor: '#1e40af', // Deeper blue for the button
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#1d4ed8', // Slightly lighter blue on hover
    },
  },
  registerLink: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: '#ffffff',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.3)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1e40af',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#1e40af',
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.root}>
      <img 
        src="/hex-pattern.svg" 
        alt="Background Pattern" 
        className={classes.backgroundPattern}
      />
      <CssBaseline />
      <Paper elevation={0} className={classes.paper}>
        <img 
          src="/sisek-logo.svg" 
          alt="SISEK Logo" 
          className={classes.logo} 
        />
        <Typography 
          component="h1" 
          variant="h5" 
          style={{ 
            marginBottom: 16, 
            color: '#ffffff', 
            fontWeight: 600 
          }}
        >
          {i18n.t("login.title")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <TextField
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={i18n.t("login.form.email")}
            name="email"
            value={user.email}
            InputLabelProps={{
              style: { color: 'white' }
            }}
            
            onChange={handleChangeInput}
            autoComplete="email"
            autoFocus
            InputProps={{
              style: { borderRadius: 12, color: '#ffffff' }
            }}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            InputLabelProps={{
              style: { color: 'white' }
            }}
            label={i18n.t("login.form.password")}
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              style: { borderRadius: 12,color: '#ffffff' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((e) => !e)}
                    style={{ color: 'rgba(255,255,255,0.7)' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            {i18n.t("login.buttons.submit")}
          </Button>

        </form>
      </Paper>
    </div>
  );
};

export default Login;