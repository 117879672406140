// HourlyChart.js
import React, { useState, useEffect } from "react"
import { useTheme } from "@material-ui/core/styles"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import { startOfHour, parseISO } from "date-fns"

const HourlyChart = ({ tickets }) => {
  const theme = useTheme()
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    // Process hourly data
    const hourlyData = Array.from({ length: 12 }, (_, i) => ({
      hora: `${String(i + 8).padStart(2, "0")}:00`,
      cantidad: 0,
    }))

    tickets.forEach(ticket => {
      const hour = new Date(ticket.createdAt).getHours()
      const index = hour - 8
      if (index >= 0 && index < 12) {
        hourlyData[index].cantidad++
      }
    })

    setChartData(hourlyData)
  }, [tickets])

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hora" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="cantidad"
          name="Tickets"
          fill={theme.palette.primary.main}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default HourlyChart