// ClientEditDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Person, Phone, Email, LocationOn, Description } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      boxShadow: theme.palette.type === 'dark' ? "0 4px 20px rgba(0,0,0,0.3)" : "0 4px 20px rgba(0,0,0,0.1)",
      background: theme.palette.type === 'dark' ? "#1a1b1e" : "#f8fafc",
      display: 'flex',
      flexDirection: 'column',
      height: '90vh',
    },
  },
  dialogHeader: {
    background: theme.palette.type === 'dark' ? "#2d2d30" : "white",
    borderBottom: `1px solid ${theme.palette.type === 'dark' ? '#404045' : '#e2e8f0'}`,
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiTypography-root': {
      color: theme.palette.type === 'dark' ? '#e2e8f0' : 'inherit',
    },
  },
  closeButton: {
    position: 'relative',
    right: theme.spacing(1),
    top: '0%',
    color: theme.palette.type === 'dark' ? '#94a3b8' : 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
    },
  },
  dialogContent: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.type === 'dark' ? "#1a1b1e" : 'inherit',
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.type === 'dark' ? '#2d2d30' : 'white',
      '& fieldset': {
        borderColor: theme.palette.type === 'dark' ? '#404045' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.type === 'dark' ? '#94a3b8' : 'inherit',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.type === 'dark' ? '#e2e8f0' : 'inherit',
    },
  },
  section: {
    backgroundColor: theme.palette.type === 'dark' ? "#2d2d30" : 'white',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: theme.palette.type === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.05)',
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: theme.palette.type === 'dark' ? '#93c5fd' : '#1e40af',
    fontWeight: 600,
    '& .MuiSvgIcon-root': {
      color: theme.palette.type === 'dark' ? '#93c5fd' : '#3b82f6',
    },
  },
  fieldIcon: {
    color: theme.palette.type === 'dark' ? '#94a3b8' : '#64748b',
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(2, 3),
    background: theme.palette.type === 'dark' ? "#2d2d30" : "white",
    borderTop: `1px solid ${theme.palette.type === 'dark' ? '#404045' : '#e2e8f0'}`,
    '& .MuiButton-outlined': {
      borderColor: theme.palette.type === 'dark' ? '#404045' : 'inherit',
      color: theme.palette.type === 'dark' ? '#e2e8f0' : 'inherit',
    },
    '& .MuiButton-contained': {
      backgroundColor: theme.palette.type === 'dark' ? '#3b82f6' : '#1e40af',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? '#2563eb' : '#1e3a8a',
      },
    },
  },
}));

const ClientEditDialog = ({ open, onClose, clientData, onSave, isNew }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_names: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    department: '',
    identification_number: '',
    notes: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (clientData) {
      setFormData({
        ...formData,
        ...clientData,
      });
    }
  }, [clientData]);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    // Clear error when field is modified
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: null
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = 'El nombre es requerido';
    if (!formData.last_names) newErrors.last_names = 'Los apellidos son requeridos';
    if (!formData.phone) newErrors.phone = 'El teléfono es requerido';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      await onSave(formData);
      onClose();
    } catch (error) {
      console.error('Error saving client:', error);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className={classes.dialog}
    >
      <div className={classes.dialogHeader}>
        <Typography variant="h6">
          {isNew ? 'Nuevo Cliente' : 'Editar cliente ' + formData.first_name + ' ' + formData.last_names}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className={classes.dialogContent}>
        {/* Personal Information */}
        <div className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <Person />
            Información Personal
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Nombre"
                required
                value={formData.first_name || ''}
                onChange={(e) => handleChange('first_name', e.target.value)}
                error={!!errors.first_name}
                helperText={errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Segundo Nombre"
                value={formData.middle_name || ''}
                onChange={(e) => handleChange('middle_name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Apellidos"
                required
                value={formData.last_names || ''}
                onChange={(e) => handleChange('last_names', e.target.value)}
                error={!!errors.last_names}
                helperText={errors.last_names}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Número de Identificación"
                value={formData.identification_number || ''}
                onChange={(e) => handleChange('identification_number', e.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        {/* Contact Information */}
        <div className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <Phone />
            Información de Contacto
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teléfono"
                required
                value={formData.phone || ''}
                onChange={(e) => handleChange('phone', e.target.value)}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={formData.email || ''}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        {/* Address Information */}
        <div className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <LocationOn />
            Dirección
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dirección"
                multiline
                rows={2}
                value={formData.address || ''}
                onChange={(e) => handleChange('address', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ciudad"
                value={formData.city || ''}
                onChange={(e) => handleChange('city', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Departamento"
                value={formData.department || ''}
                onChange={(e) => handleChange('department', e.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        {/* Additional Information */}
        <div className={classes.section}>
          <Typography className={classes.sectionTitle}>
            <Description />
            Información Adicional
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notas"
                multiline
                rows={4}
                value={formData.notes || ''}
                onChange={(e) => handleChange('notes', e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="outlined" color="default">
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : isNew ? "Crear" : "Guardar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientEditDialog;