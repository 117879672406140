import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useAllTables = () => {
    const [loading, setLoading] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        setLoading(true);
        const fetchAllTables = async () => {
            try {
                const { data } = await api.get("/tablesAll");
                setTickets(data.tickets);
                setMessages(data.messages);
                setUsers(data.users);

                setLoading(false);
            } catch (err) {
                setLoading(false);
                toastError(err);
            }
        };

        fetchAllTables();

        return () => {

        };
    }, []);

    return { tickets, messages, users, loading };
};

export default useAllTables;

